import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  container,
  siteTitle,
  date
} from './layout.module.css'


const Layout = ({ pageTitle, children }) => {
	const data = useStaticQuery(graphql`
		query {
		  site {
			siteMetadata {
			  title
			}
		  }
		}
	  `)
  return (
	<div className={container}>
	  <title>{pageTitle} | {data.site.siteMetadata.title}</title>
	  <header>
	  	<div className={siteTitle}>{data.site.siteMetadata.title}</div>
		<div className={date}>sometime after<br /> February 14th, 2023!</div>
	 </header>
	  <main>
		{children}
	  </main>
	</div>
  )
}
export default Layout