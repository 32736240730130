// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import {
  divider,
  received
} from '../components/layout.module.css'

// Step 2: Define your component
const ThanksPage = () => {
  return (
    <Layout pageTitle="Thanks!">
      <h2>On this MOST beloved of days: adventures, oddities, &amp; scholarly pursuits will be delivered to YOU.</h2>
  
      <form className="embeddable-buttondown-form">
        <p className={received}>I've received your information! You will be getting an email soon with some details. You will also be getting another email with gifts from the inaugural Post Day!</p>
        <p className={received}>Since you were kind enough to introduce yourself, here's a little bit about me.</p>
        
        <p className={divider}>❦</p>
        
        <label htmlFor="firstName">What's your first name?</label>
        <p>Sarah</p>
        
        <label htmlFor="email">What's your email?</label>
        <p>sarah AT itspostday DOT com</p>
        
        <label htmlFor="pronouns">What are your pronouns?</label>
        <p>she/her</p>
        
        <label htmlFor="curiousAbout">What are you curious about right now?</label>
        <p>Christopher Alexander, especially his work on <em>The Timeless Way of Building</em> and how its principles might apply to our everyday lives.</p>
        
        <label htmlFor="feelsAlive">What's making you come alive right now?</label>
        <p>Practicing my particular creativity again, after several years of focusing on team-building. Learning that I can still code! Ish!</p>
        
        <label htmlfor="project">Is there anything you've made or are making that you're excited about? (links welcome)</label>
        <p>It's Post Day!</p>
        <label htmlFor="fabulousPerson">Who do you think is fabulous, living or not, online or off?</label>
        <p>Marian Bantjes, who inspired me when I was a young designer. I always wanted to do something like her famous Valentine's Day cards, and at least the Valentine's Day part of the very first Post Day is entirely due to her.</p>
      </form>
      <footer><a href="https://sarahavenir.com">Who is this mail coming from?</a></footer>
    </Layout>
  )
}

// Step 3: Export your component
export default ThanksPage